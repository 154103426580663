import React, { useEffect, useState } from 'react';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { setRemovedFromGroupModal } from '@artemis/store/groupOrder/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFulfillmentId,
  getFulfillmentState,
  getIsInitialized,
  getIsLeaveFulfillmentLoading,
  getIsRemovedFromGroupModalOpen,
  getUserRemovedFromFulfillment,
} from '@artemis/store/groupOrder/selectors';
import { FULFILLMENT_STATE } from '@artemis/utils/constants';
import useMerchantMenuUrl from '@artemis/containers/Menu/useMerchantMenuUrl';
import StandardPrompt from '@artemis/components/StandardPrompt';
import { getCartFulfillmentId } from '@artemis/store/cart/selectors';

const RemovedFromGroupModal = () => {
  const dispatch = useDispatch();
  const merchantMenuURL = useMerchantMenuUrl();
  const isOpen = useSelector(getIsRemovedFromGroupModalOpen);
  const fulfillmentState = useSelector(getFulfillmentState);
  const wasUserRemoved = useSelector(getUserRemovedFromFulfillment);
  const cartFulfillmentId = useSelector(getCartFulfillmentId);
  const checkedForGroup = useSelector(getIsInitialized);
  const isLeavingGroup = useSelector(getIsLeaveFulfillmentLoading);
  const fulfillmentId = useSelector(getFulfillmentId);
  const [wasInGroup, setWasInGroup] = useState(false);

  const isNotCurrentlyInGroup = checkedForGroup && !fulfillmentId;

  const onClose = () => {
    dispatch(setRemovedFromGroupModal({ isOpen: false }));
    window.location.assign(merchantMenuURL);
  };

  useEffect(() => {
    const isCancelled = fulfillmentState === FULFILLMENT_STATE.CANCELLED;
    if (isCancelled || wasUserRemoved) {
      dispatch(setRemovedFromGroupModal({ isOpen: true }));
    }
  }, [wasUserRemoved, fulfillmentState]);

  // User was previously in group order if their cart had a fulfillment ID (see useConfigureFulfillmentCart.jsx)
  useEffect(() => {
    if (cartFulfillmentId) {
      setWasInGroup(true);
    }
  }, [cartFulfillmentId]);

  // Show modal if user was previously in group order (based on cart) but no longer is
  useEffect(() => {
    if (wasInGroup && isNotCurrentlyInGroup && !isLeavingGroup) {
      dispatch(setRemovedFromGroupModal({ isOpen: true }));
    }
  }, [wasInGroup, isNotCurrentlyInGroup, isLeavingGroup]);

  return (
    <StandardPrompt
      primaryButtonText={
        <FormattedMessage entry="groupOrder.removedFromGroupModal.button" />
      }
      title={
        <FormattedMessage entry="groupOrder.removedFromGroupModal.title" />
      }
      content={
        <FormattedMessage entry="groupOrder.removedFromGroupModal.description" />
      }
      onClose={onClose}
      isOpen={isOpen}
      onClickPrimaryButton={onClose}
      isClosable={false}
      size="sm"
    />
  );
};

export default RemovedFromGroupModal;
